// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function SettingsSvgrepoCom1SvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 30 30"}
      version={"1.1"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M27.52 21.134l-.992 1.732a.987.987 0 01-1.355.366l-2.755-1.604a9.882 9.882 0 01-5.435 3.171V27c0 .553-.443 1-.991 1h-1.984a.995.995 0 01-.991-1v-2.201a9.882 9.882 0 01-5.435-3.171l-2.755 1.604a.987.987 0 01-1.355-.366l-.992-1.732a1.003 1.003 0 01.363-1.365l2.766-1.612A10.02 10.02 0 015.083 15c0-1.106.197-2.162.526-3.157l-2.766-1.611a1.005 1.005 0 01-.363-1.366l.992-1.732a.988.988 0 011.355-.366l2.755 1.604a9.882 9.882 0 015.435-3.171V3c0-.553.443-1 .991-1h1.984c.548 0 .991.447.991 1v2.201a9.882 9.882 0 015.435 3.171l2.755-1.604a.988.988 0 011.355.366l.992 1.732c.274.479.111 1.09-.363 1.366l-2.766 1.611c.329.995.526 2.051.526 3.157a10.02 10.02 0 01-.526 3.157l2.766 1.612c.474.275.637.887.363 1.365zm1.488-2.598l-2.323-1.352c.13-.71.216-1.435.216-2.184 0-.748-.086-1.474-.216-2.184l2.323-1.352a2.008 2.008 0 00.725-2.732L27.75 5.268a1.974 1.974 0 00-2.709-.732l-2.347 1.365c-1.096-.94-2.342-1.709-3.727-2.204V2c0-1.104-.888-2-1.984-2h-3.966a1.992 1.992 0 00-1.984 2v1.697c-1.385.495-2.631 1.264-3.727 2.204L4.959 4.536a1.974 1.974 0 00-2.709.732L.267 8.732a2.01 2.01 0 00.725 2.732l2.323 1.352c-.13.71-.216 1.436-.216 2.184 0 .749.086 1.474.216 2.184L.992 18.536a2.01 2.01 0 00-.725 2.732l1.983 3.464a1.974 1.974 0 002.709.732l2.347-1.365c1.096.94 2.342 1.71 3.727 2.204V28c0 1.104.888 2 1.984 2h3.966a1.992 1.992 0 001.984-2v-1.697c1.385-.494 2.631-1.264 3.727-2.204l2.347 1.365a1.974 1.974 0 002.709-.732l1.983-3.464a2.008 2.008 0 00-.725-2.732zM15 18c-1.643 0-2.975-1.343-2.975-3 0-1.656 1.332-3 2.975-3s2.975 1.344 2.975 3c0 1.657-1.332 3-2.975 3zm0-8c-2.739 0-4.958 2.238-4.958 5s2.219 5 4.958 5c2.739 0 4.959-2.238 4.959-5s-2.22-5-4.959-5z"
        }
        fill={"currentColor"}
        fillRule={"evenodd"}
      ></path>
    </svg>
  );
}

export default SettingsSvgrepoCom1SvgIcon;
/* prettier-ignore-end */
