// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicDocumentRow } from "./plasmic/prism/PlasmicDocumentRow";

function DocumentRow_(props, ref) {

  function handleClick() {
    props.clickHandler(props.id);
  }

  return <PlasmicDocumentRow root={{ ref }} {...props} onClick={() => handleClick()} />;
}

const DocumentRow = React.forwardRef(DocumentRow_);

export default DocumentRow;
