
import Homepage from './components/Homepage';
import ClientDetails from './components/ClientDetails';
import DocumentDetails from './components/DocumentDetails';
import Settings from './components/Settings';
import Features from './components/Features';
import PrismicInsightsHomepage from './components/PrismicInsightsHomepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from "react";
import { PlasmicCanvasHost } from '@plasmicapp/react-web/lib/host';
import { registerComponent } from '@plasmicapp/react-web/lib/host';
import { Upload, Button } from 'antd';
import CreatableSelect from 'react-select/creatable';
import { useSnackbar } from 'notistack';

registerComponent(CreatableSelect, {
  name: 'CreatableSelect',
  props: {},
  isDefaultExport: true,
  importPath: 'react-select/creatable'
});

registerComponent(Button, {
  name: 'AntButton',
  props: {
    key: 'string',
    children: 'slot'
  },
  importPath: 'antd',
  isDefaultExport: false,
  importName: 'Button'
});

registerComponent(Upload, {
  name: "AntUpload",
  props: {
    children: {
      type: 'slot',
      allowedComponents: ['AntButton'],
      defaultValue: [
        {
          type: 'component',
          name: 'AntButton',
          props: {
            key: 'uploadButton',
            children: {
              type: 'text',
              value: 'Upload'
            }
          }
        }
      ]
    }
  },
  importPath: 'antd',
  isDefaultExport: false,
  importName: 'Upload'
});

function App() {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const snackbarAction = snackbarId => (
        <>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            Dismiss
        </Button>
        </>
    );

    const snackbarOptions = { anchorOrigin: { horizontal: "right", vertical: "top"},
                                action: snackbarAction,
                                autoHideDuration: null
                            };

    const originalFetch = window.fetch;

    window.fetch = function() {
        return originalFetch.apply(this, arguments)
            .then(async function(response) {
                if (!response.ok) {
                    const responseJson = await response.json();
                    console.error(responseJson);
                    enqueueSnackbar(responseJson.message, snackbarOptions);
                }
                return response;
            })
            .catch(error => {
                console.error('Global fetch error:', error);
                throw error;
            });
    };

  useEffect(() => {
    document.title = "Prism";
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<PrismicInsightsHomepage />} />
        <Route path='/prism-features' element={<Features />} />
        <Route path='/app' element={<Homepage />} />
        <Route path='/app/client-details' element={<ClientDetails />} />
        <Route path='/app/document-details' element={<DocumentDetails />} />
        <Route path='/app/settings' element={<Settings />} />
        <Route path="/plasmic-host" element={<PlasmicCanvasHost />} />
      </Routes>
    </Router>    
  );
}

export default App;
  