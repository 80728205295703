// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicHomepage } from "./plasmic/prism/PlasmicHomepage";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ClientRow from "./ClientRow";
import DocumentRow from "./DocumentRow";

function Homepage_(props, ref) {

  const [clients, setClients] = useState([]);
  const [newClientName, setNewClientName] = useState("");
  const [newClientEmail, setNewClientEmail] = useState("");
  const [user, setUser] = useState({ firstName: "Mike", lastName: "Tamayo", _id: "64b761babfd73a2e72c4d5db"});
  const [documents, setDocuments] = useState([]);
  const [newClientNameMissing, setNewClientNameMissing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  async function handleNewClientOkClick() {
    
    if (newClientName.length === 0) {
      setNewClientNameMissing(true);
      setModalOpen(true);
    }
    else {
      setNewClientNameMissing(false);
      const response = await fetch("/app/person/create",
      {
        method: "POST", 
        body: JSON.stringify( { type: "client", name: newClientName, owner: user._id } ),
        headers: {
          "Content-Type": "application/json",
        }
      });

      const clientId = await response.json();

      navigate("/app/client-details", { state: { id: clientId }});
      setNewClientName("");
      setNewClientEmail("");
      setModalOpen(false);
    }


  }

  function handleNewClientCancelClick() {
    setModalOpen(false);
    setNewClientName("");
    setNewClientEmail("");
  }

  async function fetchPageData() {
    let response = await fetch("/app/user/get",
    {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
      }
    });
    const userJson = await response.json();
    setUser(userJson);

    response = await fetch("/app/person/get",
          {
            method: "POST", 
            body: JSON.stringify( { owner: userJson._id } ),
            headers: {
              "Content-Type": "application/json",
            }
          });
    const clientsJson = await response.json();
    setClients(clientsJson);

    response = await fetch("/app/document/getLatest",
    {
      method: "POST", 
      body: JSON.stringify( { userId: userJson._id } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const documentsJson = await response.json();
    setDocuments(documentsJson);
  }

  function handleClientClick(clientId) {
    navigate("/app/client-details", { state: { id: clientId }});
  }

  function handleDocumentClick(documentId) {
    navigate("/app/document-details", { state: { documentId: documentId }});
  }

  function openNewClientDialog() {
    setNewClientName("");
    setNewClientEmail("");
    setNewClientNameMissing(false);
    setModalOpen(true);
  }

  function handleNameChange(name) {
    setNewClientName(name);
    if (name.length > 0) {
      setNewClientNameMissing(false);
    }
  }

  useEffect(() => { fetchPageData(); }, []);

  return <PlasmicHomepage root={{ ref }} {...props}
                          addClientButton={{ onClick: (e) => openNewClientDialog() }}
                          newClientDialog={{ props: { onOk: handleNewClientOkClick, onCancel: handleNewClientCancelClick, open: modalOpen } }}
                          name={{ value: newClientName, onChange: (e) => handleNameChange(e.target.value) }}
                          nameWrapper={{ error: newClientNameMissing }}
                          email={{ value: newClientEmail, onChange: (e) => setNewClientEmail(e.target.value) }}
                          errorText={{ props: { children: (newClientNameMissing ? "Please complete the highlighted fields." : "" ) } }}
                          clientTable={{ props: { children: clients.map((client) => <ClientRow name={client.name} id={client._id} lastDocumentDate={(client.lastDocument[0] == null) ? "" : new Date(client.lastDocument[0].date).toLocaleDateString()} clickHandler={handleClientClick} /> ) }}}
                          documentTable={{ props: { children: documents.map((document) => <DocumentRow id={document._id} client={document.person} type={document.type} date={new Date(document.date).toLocaleDateString()} status={document.status} clickHandler={handleDocumentClick} />) }}}
          />;
}

const Homepage = React.forwardRef(Homepage_);

export default Homepage;
