// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicDocumentDetails } from "./plasmic/prism/PlasmicDocumentDetails";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import InsightRow from "./InsightRow";

function DocumentDetails_(props, ref) {

  const [document, setDocument] = useState({ type: "", text: "", date: null, status: "new" });
  const [client, setClient] = useState({});
  const [insights, setInsights] = useState([]);
  const [transcriptClientName, setTranscriptClientName] = useState("");
  const [transcriptCoachName, setTranscriptCoachName] = useState("");
  const [transcriptSpeakers, setTranscriptSpeakers] = useState([{label: "Speaker 1", value: "Speaker 1"}, {label: "Speaker 2", value: "Speaker 2"}]);
  const [documentTypeMissing, setDocumentTypeMissing] = useState(false);
  const [documentDateMissing, setDocumentDateMissing] = useState(false);
  const [transcriptCoachNameMissing, setTranscriptCoachNameMissing] = useState(false);
  const [transcriptClientNameMissing, setTranscriptClientNameMissing] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  async function fetchPageData() {
    if (location.state.clientId !== undefined) {
      fetchClient(location.state.clientId);
    }
    else if (location.state.documentId !== undefined) {
      fetchDocument(location.state.documentId);
    }
  }

  async function fetchDocument(documentId) {
    let response = await fetch("/app/document/get",
    {
      method: "POST", 
      body: JSON.stringify( { id: location.state.documentId } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const documentJson = await response.json();
    setDocument(documentJson);
  }

  async function fetchClient(clientId) {
    let response = await fetch("/app/person/get",
    {
      method: "POST", 
      body: JSON.stringify( { id: clientId } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const clientJson = await response.json();
    setClient(clientJson);
  }

  async function fetchInsights(docId) {
    if (docId === undefined) {
      docId = document._id;
    }

    let response = await fetch("/app/personData/get",
    {
      method: "POST", 
      body: JSON.stringify( { document: docId } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const insightsJson = await response.json();
    console.log("in fetchInsights()");
    console.log(insightsJson);
    setInsights(insightsJson);
  }

  async function handleSaveButtonClick() {
    let dataMissing = false;

    if (document.type.length === 0) {
      setDocumentTypeMissing(true);
      dataMissing = true;
    }
    if (document.date == null) {
      setDocumentDateMissing(true);
      dataMissing = true;
    }
    if (document.type === 'transcript') {
      if (transcriptCoachName.length === 0) {
        setTranscriptCoachNameMissing(true);
        dataMissing = true;
      }
      if (transcriptClientName.length === 0) {
        setTranscriptClientNameMissing(true);
        dataMissing = true;
      }
    }
    if (!dataMissing) {
      setDocumentTypeMissing(false);
      setDocumentDateMissing(false);
      setTranscriptCoachNameMissing(false);
      setTranscriptClientNameMissing(false);

      let docId = null;

      if (document._id == null) {

        let response = await fetch("/app/document/create",
          {
            method: "POST", 
            body: JSON.stringify( { person: client._id, type: document.type, text: document.text, date: document.date, coachName: transcriptCoachName, clientName: transcriptClientName } ),
            headers: {
              "Content-Type": "application/json",
            }
          });
        docId = await response.text();
        docId = docId.replaceAll('"', '');
        setDocumentField("_id", docId);
      }
      else {
        docId = document._id;
      }

      fetch("/app/document/process",
      {
        method: "POST", 
        body: JSON.stringify( { docid: docId } ),
        headers: {
          "Content-Type": "application/json",
        }
      }).then(() => fetchInsights(docId));

      navigate("/app/client-details", { state: { id: client._id }});
    }
  }

  function setDocumentField(name, value) {
    setDocument((prevDocument) => ({
      ...prevDocument,
      [name]: value
    }));
  }

  async function handleDeleteDocument() {
    await fetch("/app/document/delete",
    {
      method: "POST", 
      body: JSON.stringify( { id: document._id } ),
      headers: {
        "Content-Type": "application/json",
      }
    });

    navigate("/app/client-details", { state: { id: client._id }});
  }

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    async beforeUpload(file) {

      const formData = new FormData();
      formData.append("file", file);
      let response = await fetch("/app/document/extractText",
      {
        method: "POST",
        body: formData
      });
      let responseText = await response.text();
      setDocumentField("text", responseText);
      if (document.type === "transcript") {
        detectSpeakers(responseText);
      }

      return false;
    }
  }

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  function detectSpeaker(line) {
    const pattern = /^(?:\d{1,2}:\d{2} )?([^.,?!:\n]+)(?: \d{1,2}:\d{2})?$/;
    const match = line.match(pattern);
    if (match) {
      const name = match[1] || match[2];
      return name.trim();
    }
    return null;
  }
  
  // Function to process the transcript and detect speakers
  function detectSpeakers(transcript) {
    console.log("in detectSpeakers");
    const lines = transcript.split('\n');
    const speakers = new Set();
  
    for (let ii=0; ii < 100 && ii < lines.length; ++ii) {
      let line = lines[ii].trim();

      if (line.length > 0) {
        let match = detectSpeaker(line);
        if (match) {
          speakers.add(match);
    
          while (ii < lines.length && lines[ii].trim().length > 0) {
            ++ii;
          }
        }
      }
    }
  
    console.log("detected speakers:");
    console.log(speakers);
    let speakerSelectItems = [];
    speakers.forEach((speaker) => { speakerSelectItems.push( {label: speaker, value: speaker} ); } );
    setTranscriptSpeakers(speakerSelectItems);
  }

  function handleDocumentTypeChange(type) {
    setDocumentField("type", type);
    if (type === "transcript") {
      detectSpeakers(document.text);
    }
    setDocumentTypeMissing(false);
  }

  function handleDocumentDateChange(date) {
    setDocumentField("date", date);
    if (date != null) {
      setDocumentDateMissing(false);
    }
  }

  function handleTranscriptCoachNameChange(name) {
    setTranscriptCoachName(name);
    if (name.value.length > 0) {
      setTranscriptCoachNameMissing(false);
    }
  }

  function handleTranscriptClientNameChange(name) {
    setTranscriptClientName(name);
    if (name.value.length > 0) {
      setTranscriptClientNameMissing(false);
    }
  }

  useEffect(() => { fetchPageData(); }, []);
  useEffect(() => { if (client.name == null && document.person != null) fetchClient(document.person); }, [document.person]);
  useEffect(() => { if (document._id != null) fetchInsights(); }, [document._id]);

  return <PlasmicDocumentDetails root={{ ref }} {...props}
                                 clientName={client.name}
                                 documentTypeWrapper={{ error: documentTypeMissing }}
                                 documentType={{ value: document.type, onChange: (e) => { handleDocumentTypeChange(e); }}}
                                 documentDateWrapper={{ error: documentDateMissing }}
                                 documentDate={{ value: document.date, onChange: (e) => { handleDocumentDateChange(e); }}}
                                 errorText={{ props: { children: ((documentTypeMissing || documentDateMissing || transcriptCoachNameMissing || transcriptClientNameMissing) ? "Please complete the highlighted fields." : "" ) } }}
                                 documentStatus={document.status}
                                 documentText={{ value: document.text, onChange: (e) => { setDocumentField("text", e.target.value); }}}
                                 saveButton={{ props: { onClick: handleSaveButtonClick }}}
                                 insightTable={{ props: { children: insights.map((insight) => <InsightRow name={insight.value} type={insight.type} date={new Date(insight.date).toLocaleDateString()} />) }}}
                                 deleteDocumentDialog={{ props: { onOk: handleDeleteDocument } }}
                                 saveButtonText={{ props: { children: (document._id == null) ? "Save" : "Process"}}}
                                //  transcriptCoachName={{ value: transcriptCoachName, onChange: (e) => { setTranscriptCoachName(e.target.value); }}}
                                 transcriptCoachName={{ options: transcriptSpeakers, value: transcriptCoachName, onChange: (e) => { handleTranscriptCoachNameChange(e); }}}
                                 transcriptCoachNameWrapper={{ error: transcriptCoachNameMissing }}
                                 //  transcriptClientName={{ value: transcriptClientName, onChange: (e) => { setTranscriptClientName(e.target.value); }}}
                                 transcriptClientName={{ options: transcriptSpeakers, value: transcriptClientName, onChange: (e) => { handleTranscriptClientNameChange(e); }}}
                                 transcriptClientNameWrapper={{ error: transcriptClientNameMissing }}
                                 fileUpload={{ ...uploadProps }}
         />;
}

const DocumentDetails = React.forwardRef(DocumentDetails_);

export default DocumentDetails;
