// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicInsightAggregatePanel } from "./plasmic/prism/PlasmicInsightAggregatePanel";
import InsightAggregateRow from "./InsightAggregateRow";

function InsightAggregatePanel_(props, ref) {

  return <PlasmicInsightAggregatePanel root={{ ref }} {...props}
                                       strengthsTable={{ props: { children: props.strengths.map((strength) => <InsightAggregateRow name={strength._id} count={strength.count} />) }}}
                                       valuesTable={{ props: { children: props.values.map((value) => <InsightAggregateRow name={value._id} count={value.count} />) }}}
                                       themesTable={{ props: { children: props.themes.map((theme) => <InsightAggregateRow name={theme._id} count={theme.count} />) }}}
         />;
}

const InsightAggregatePanel = React.forwardRef(InsightAggregatePanel_);

export default InsightAggregatePanel;
