// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicSettings } from "./plasmic/prism/PlasmicSettings";

function Settings_(props, ref) {

  return <PlasmicSettings root={{ ref }} {...props}
         />;
}

const Settings = React.forwardRef(Settings_);

export default Settings;
