// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicClientRow } from "./plasmic/prism/PlasmicClientRow";

function ClientRow_(props, ref) {

  function handleClick() {
    props.clickHandler(props.id);
  }

  return <PlasmicClientRow clientRow={{ ref }} {...props} onClick={() => handleClick()} />;
}

const ClientRow = React.forwardRef(ClientRow_);

export default ClientRow;
