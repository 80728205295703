// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicNavigationEntry } from "./plasmic/prism/PlasmicNavigationEntry";

function NavigationEntry_(props, ref) {
  // Use PlasmicNavigationEntry to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicNavigationEntry are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all NavigationEntryProps here, but feel free
  // to do whatever works for you.
  return <PlasmicNavigationEntry root={{ ref }} {...props} />;
}

const NavigationEntry = React.forwardRef(NavigationEntry_);

export default NavigationEntry;
