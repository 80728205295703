// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRequiredInput } from "./plasmic/prism/PlasmicRequiredInput";

function RequiredInput_(props, ref) {

  function handleInputChange(value) {
    console.log("in handleInputChange");
    console.log(value);
  }
  return <PlasmicRequiredInput root={{ ref }} {...props}
                               inputField={{ defaultValue: props.defaultValue }}
  />;
}

const RequiredInput = React.forwardRef(RequiredInput_);

export default RequiredInput;
